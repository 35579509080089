import React from 'react'
import DateDisplayStatic from '../components/DateDisplayStatic'

const HomePage: React.FC = () => {
    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h1 style={{ fontSize: '4em' }}>Let us deploy a change!</h1>
            <p>Now we are running through the WAF.</p>
            <DateDisplayStatic />
        </div>
    )
}

export default HomePage
